import { React, useState, useEffect } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import CompanyPropService from '../../services/companyprop.service';
import axios from 'axios';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';
import { useTranslation } from 'react-i18next';
import property_logo from './tree-city-square.png';
import commonVariables from '../../config/commonSetting';

const hostname = commonVariables.hostname;

const SidebarHeader = (props) => {
  let currentCompProp = CompanyPropService;

  const { t } = useTranslation();
  const selectedLang = localStorage.getItem("i18nextLng") || "en";

  let [companyName, setCompanyName] = useState('...Loading...');
  let [propLogo, setPropLogo] = useState(property_logo);

  let setHeaderIcon = (property) => {
    if (
      !(
        property.property_logo === '' ||
        property.property_logo === undefined ||
        property.property_logo === null
      )
    ) {
      axios
        .get(hostname + '/property/icon/' + property.property_logo, {
          responseType: 'blob'
        })
        .then((result) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(result.data);
          reader.onload = function () {
            var imageDataUrl = reader.result;
            setPropLogo(imageDataUrl);
          };
        });
    }
  };

  useEffect(() => {
    let currentCompany = currentCompProp.getCurrentCompany();
    let currentProperty = currentCompProp.getCurrentProperty();
    let displayCompanyName = '...Loading...';

    if (currentCompany) {
      if (selectedLang === 'en') {
        displayCompanyName = currentCompany.company_name_en;
      } else if (selectedLang === 'tc') {
        displayCompanyName = currentCompany.company_name_tc;
      } else if (selectedLang === 'sc') {
        displayCompanyName = currentCompany.company_name_sc;
      }
    } else {
      if (selectedLang === 'en') {
        displayCompanyName = 'Load Company error';
      } else if (selectedLang === 'tc') {
        displayCompanyName = '讀取公司資料錯誤';
      } else if (selectedLang === 'sc') {
        displayCompanyName = '读取公司资料错误';
      }
    }

    setCompanyName(displayCompanyName);
    setHeaderIcon(currentProperty);
  }, [currentCompProp, selectedLang]);

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle
  } = props;

  return (
    <>
      {/* here is sidebar style={{marginTop: '30%'}}*/}
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink
            to="/PageHome"
            title="LiTe Smart Building System"
            className="app-sidebar-logo">
            <div className="avatar-icon-wrapper avatar-icon-md">
              <div className="avatar-icon" style={{ borderRadius: '10px' }}>
                <img alt="..." src={propLogo} />
              </div>
            </div>

            <div className="app-sidebar-logo--text">
              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {t('sidebar_smarttoilet')}
              </span>

              <b>{companyName}</b>
            </div>
          </NavLink>
        </div>
        <button
          onClick={toggleSidebar}
          className="btn btn-sm collapse-sidebar-btn"
          id="CollapseSidebarTooltip">
          <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
        </button>
        <UncontrolledTooltip
          target="CollapseSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          {t('Collapse_sidebar')}
        </UncontrolledTooltip>
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <button
          onClick={toggleSidebar}
          className="expand-sidebar-btn btn btn-sm"
          id="ExpandSidebarTooltip">
          <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
        </button>
        <UncontrolledTooltip
          target="ExpandSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          {t('Expand_sidebar')}
        </UncontrolledTooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
