import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  UncontrolledTooltip,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import property_logo from './tree-city-square.png';
import { Menu, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import CompanyPropService from '../../services/companyprop.service';

import commonVariables from '../../config/commonSetting';
import { Col, Row } from 'antd';
const { Option } = Select;
const hostname = commonVariables.hostname;

const SidebarUserbox = () => {
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem("i18nextLng") || "en";

  // let currentUser = AuthService.getCurrentUser();
  let currentCompProp = CompanyPropService;
  //Favor Industrial Centre
  let [propertyName, setPropertyName] = useState('...Loading...');
  let [companyList, setcompanyList] = useState([]);
  let [propertyList, setpropertyList] = useState([]);

  let [propLogo, setPropLogo] = useState(property_logo);

  let changeProperty = (property) => {
    let displayPropertyName = '...Loading...';

    if (selectedLang === 'en') {
      displayPropertyName = property.property_name_en;
    } else if (selectedLang === 'tc') {
      displayPropertyName = property.property_name_tc;
    } else if (selectedLang === 'sc') {
      displayPropertyName = property.property_name_sc;
    }
    setPropertyName(displayPropertyName);
    if (
      !(
        property.property_logo === '' ||
        property.property_logo === undefined ||
        property.property_logo === null
      )
    ) {
      setPropLogo(hostname + '/property/icon/' + property.property_logo);
    }
  };

  useEffect(() => {
    // console.log(currentCompProp.getCanRouteProperties());
    changeProperty(currentCompProp.getCurrentProperty());
    setpropertyList(currentCompProp.getCanRouteProperties());
  }, [currentCompProp, selectedLang]);

  const handleChangeProperty = function (property) {
    // console.log("Property changed");
    // console.log(JSON.stringify(property));

    currentCompProp.setCurrentProperty(property);
    if (currentCompProp.getCurrentCompany().company != property.company) {
      currentCompProp.setCurrentCompany(
        currentCompProp.getCompByCompId(property.company)
      );
    }
    // changeProperty(property);
    window.location.reload();
  };

  let [items, setItems] = useState([]);
  useEffect(() => {
    setcompanyList(currentCompProp.getCanRouteCompanies());
    setpropertyList(currentCompProp.getCanRouteProperties());
  }, []);

  useEffect(() => {
    let updateditems = [];
    for (let i = 0; i < companyList.length; i++) {
      let company = companyList[i];
      let children = [];
      for (let j = 0; j < propertyList.length; j++) {
        let property = propertyList[j];
        if (property.company === company.company) {
          children.push(
            getItem(
              selectedLang == 'tc'
                ? property.property_name_tc
                : selectedLang == 'sc'
                ? property.property_name_sc
                : property.property_name_en,
              property.property,
              null,
              null,
              'item'
            )
          );
        }
      }
      updateditems.push(
        getItem(
          selectedLang == 'tc'
            ? company.company_name_tc
            : selectedLang == 'sc'
            ? company.company_name_sc
            : company.company_name_en,
          company.company,
          null,
          children,
          'sub' + i
        )
      );
    }
    setItems(updateditems);
  }, [companyList, propertyList]);

  const onClickMenu = (e) => {
    console.log('click ', e);
    let company = e.keyPath[1];
    let property = e.keyPath[0];
    console.log('company', company);
    console.log('property', property);
    currentCompProp.setCurrentProperty(
      currentCompProp.getPropByPropId(property)
    );
    if (currentCompProp.getCurrentCompany().company != company) {
      currentCompProp.setCurrentCompany(
        currentCompProp.getCompByCompId(company)
      );
    }
    window.location.reload();
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }

  return (
    <>
      <div className="app-sidebar--userbox">
        <UncontrolledDropdown className="card-tr-actions">
          <DropdownToggle
            color="link"
            className="p-0 border-0 d-30 text-white-50">
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className="font-size-lg"
            />
          </DropdownToggle>

          <DropdownMenu
            right
            className="text-nowrap dropdown-menu-lg overflow-hidden p-0">
            <div className="align-box-row align-items-center p-3">
              <div className="avatar-icon-wrapper avatar-icon-md">
                <div className="avatar-icon rounded-circle">
                  <img alt="..." src={propLogo} />
                </div>
              </div>
              <div className="pl-2">
                <span style={{ color: '#3b5999', fontWeight: 'bold' }}>
                  {propertyName}
                </span>
                {/* <Badge color="success">{t('active')}</Badge> */}
              </div>
            </div>

            <div className="divider" />
            <div className="d-block rounded-bottom py-3 text-center">
              {items.length > 0 && (
                <Menu
                  onClick={onClickMenu}
                  style={{
                    width: 256
                  }}
                  defaultSelectedKeys={[
                    currentCompProp.getCurrentProperty(),
                    currentCompProp.getCurrentCompany()
                  ]}
                  defaultOpenKeys={['sub1']}
                  mode="inline"
                  items={items}
                />
              )}
              {false && (
                <Row gutter={[0, 16]}>
                  {propertyList.map((property, key) => (
                    <Col span={12} key={key}>
                      <Button
                        id={'prop-' + key}
                        color="facebook"
                        onClick={() => {
                          handleChangeProperty(property);
                        }}>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={faBuilding} size="lg" />
                        </span>
                      </Button>
                      <div style={{ fontSize: '12px' }}>
                        {(selectedLang === 'en' && (
                          <>{property.property_name_en}</>
                        )) ||
                          (selectedLang === 'tc' && (
                            <>{property.property_name_tc}</>
                          )) ||
                          (selectedLang === 'sc' && (
                            <>{property.property_name_sc}</>
                          ))}
                      </div>
                      <UncontrolledTooltip target={'prop-' + key}>
                        {(selectedLang === 'en' && (
                          <>{property.property_name_en}</>
                        )) ||
                          (selectedLang === 'tc' && (
                            <>{property.property_name_tc}</>
                          )) ||
                          (selectedLang === 'sc' && (
                            <>{property.property_name_sc}</>
                          ))}
                      </UncontrolledTooltip>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>

        {/* <div className="avatar-icon-wrapper avatar-icon-md">
          <div className="avatar-icon rounded-circle">
            <img alt="..." src={propLogo} />
          </div>
        </div> */}
        <div
          style={{ fontWeight: 'bold', marginTop: '10px', fontSize: '18px' }}>
          {propertyName}
          {/* <small className="d-block text-white-50">
            2 Kin Hong Street, Kwai Chung
          </small> */}
        </div>
        {/* <Button size="sm" tag={NavLink} to="/PageProfile" color="userbox">
          View profile
        </Button> */}
      </div>
    </>
  );
};

export default SidebarUserbox;
