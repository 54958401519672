import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Sidebar, Header, Footer } from '../../layout-components';
//import background2 from './nasa-Q1p7bh3SHj8-unsplash.jpg';
import background2 from './cyberport.png';
import { Layout, Menu, Breadcrumb } from 'antd';

import axios from 'axios';

import CompanyPropService from '../../services/companyprop.service';

import commonVariables from '../../config/commonSetting';

//////////////////try tabs
import { StickyContainer, Sticky } from 'react-sticky';
import { Tabs, Radio } from 'antd';
const { TabPane } = Tabs;

const hostname = commonVariables.hostname;
let currentCompProp = CompanyPropService;

const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground
  } = props;

  let [page_bg, setPageBg] = useState(background2);
  // Location for company background image (page_bg)

  useEffect(() => {
    // console.log(currentCompProp.getCurrentCompany());
    // console.log(currentCompProp.getCurrentProperty());
    if (
      !(
        currentCompProp.getCurrentProperty().property_bg === '' ||
        currentCompProp.getCurrentProperty().property_bg === undefined ||
        currentCompProp.getCurrentProperty().property_bg === null
      )
    ) {
      // console.log('Property has bg');
      axios
        .get(
          hostname +
            '/property/bg/' +
            currentCompProp.getCurrentProperty().property_bg,
          { responseType: 'blob' }
        )
        .then((result) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(result.data);
          reader.onload = function () {
            var imageDataUrl = reader.result;
            setPageBg(imageDataUrl);
          };
        });
    } else {
      if (
        !(
          currentCompProp.getCurrentCompany().company_bg === '' ||
          currentCompProp.getCurrentCompany().company_bg === undefined ||
          currentCompProp.getCurrentCompany().company_bg === null
        )
      ) {
        // console.log('Company has bg');
        axios
          .get(
            hostname +
              '/company/bg/' +
              currentCompProp.getCurrentCompany().company_bg,
            { responseType: 'blob' }
          )
          .then((result) => {
            var reader = new window.FileReader();
            reader.readAsDataURL(result.data);
            reader.onload = function () {
              var imageDataUrl = reader.result;
              setPageBg(imageDataUrl);
            };
          });
      }
      else{
        console.log("No bg images?")
      }
    }
  }, []);

  return (
    <>
      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>


        <div>
          <Sidebar />
        </div>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div
              className="app-content--inner"
              style={{
                backgroundImage: 'url(' + page_bg + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }}>
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
