import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import AuthService from '../../services/auth.service';
import { BackTop, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import 'antd/dist/antd.css';
//import './index.css';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import OnlyPlayPauseButton from '../../myPages/howler/players/OnlyPlayPauseButton';
import WaterLeakageAlarm from '../../components/SocketWaterLeakage';
import avatar7 from './cyberport_user.png';
import { DownOutlined } from '@ant-design/icons';
import { Language } from '@styled-icons/ionicons-solid/Language';
import { LogOut } from '@styled-icons/boxicons-regular/LogOut';
import avanlogo from './linked_tech.png';
////hidden
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import { NavLink as NavLinkStrap } from 'reactstrap';

import {
  Badge,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import commonVariables from '../../config/commonSetting';

import CompanyPropService from '../../services/companyprop.service';
// let currentCompProp = CompanyPropService;

const languageMap = commonVariables.languageMap;

///////pop-up form

const LanguageSelect = () => {
  const selectedLang = localStorage.getItem("i18nextLng") || "en";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [modal4, setModal4] = useState(false);

  useEffect(() => {
    document.body.dir = languageMap[selectedLang].dir;
  }, [menuAnchor, selectedLang]);

  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let currentCompProp = CompanyPropService;

  const logOut = () => {
    let returnToLoginPlain = currentCompProp.getKeepPlain();
    AuthService.logout();
    // console.log('returnToLoginPlain', returnToLoginPlain);
    //  if (returnToLoginPlain) {
    // console.log('Go to Plain');
    // history.push('LiTe/#/PageLoginPlain');
    // } else {
    // console.log('Go to original');
    history.push('/PageLogin');
    // }
    window.location.reload();
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };

  const currentUser = AuthService.getCurrentUser();

  const [username, setUsername] = useState('');
  const [userlevel, setUserlevel] = useState('');

  useEffect(() => {
    setUsername(currentUser.emp_name);
    setUserlevel(
      currentUser.level == 0 ? (
        <Tag color="gold">{t('Level0')}</Tag>
      ) : currentUser.level == 1 ? (
        <Tag color="green">{t('Level1')}</Tag>
      ) : currentUser.level == 2 ? (
        <Tag color="cyan">{t('Level2')}</Tag>
      ) : (
        <Tag color="blue">{t('Level3')}</Tag>
      )
    );
  }, []);

  const toggle4 = () => setModal4(!modal4);
  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        {/* <OnlyPlayPauseButton /> */}
        <WaterLeakageAlarm />
        <Hidden xsDown>
          <span className="d-inline-block pr-2">
            <Button
              style={{ backgroundColor: 'inherit', width: '150px' }}
              onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
              <Language size="16" />
              {languageMap[selectedLang].label}
              <ArrowDropDown fontSize="small" />
            </Button>

            <Popover
              open={!!menuAnchor}
              anchorEl={menuAnchor}
              onClose={() => setMenuAnchor(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}>
              <div>
                <List>
                  <ListSubheader>{t('select_language')}</ListSubheader>
                  {Object.keys(languageMap)?.map((item) => (
                    <ListItem
                      button
                      key={item}
                      onClick={() => {
                        i18next.changeLanguage(item);
                        setMenuAnchor(null);
                        refreshPage();
                      }}>
                      {languageMap[item].label}
                    </ListItem>
                  ))}
                </List>
              </div>
            </Popover>
          </span>
        </Hidden>

        <UncontrolledDropdown className="position-relative ml-2">
          <DropdownToggle
            color="link"
            className="p-0 text-left d-flex btn-transition-none align-items-center">
            {/* <div className="d-block p-0 avatar-icon-wrapper">
              <Badge color="success" className="badge-circle p-top-a">
                Online
              </Badge>
              <div className="avatar-icon rounded">
                <img src={avatar7} alt="..." />
              </div>
            </div> */}
            <div className="d-none d-xl-block pl-2">
              <div className="font-weight-bold">{username}</div>
              <span className="text-black-50">{userlevel}</span>
            </div>
            <span className="pl-1 pl-xl-3">
              <FontAwesomeIcon
                icon={['fas', 'angle-down']}
                className="opacity-5"
              />
            </span>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
            <ListGroup flush className="text-left bg-transparent">
              <ListGroupItem className="rounded-top">
                <Nav pills className="nav-neutral-primary flex-column">
                  <NavItem className="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                    <div>{t('profile_option')}</div>
                  </NavItem>
                  <NavItem>
                    <NavLinkStrap href="#/PageProfileEdit" onClick={() => {}}>
                      My Account
                    </NavLinkStrap>
                  </NavItem>
                  {/* <NavItem>
                  <NavLinkStrap href="#/" onClick={(e) => e.preventDefault()}>
                    Profile settings
                  </NavLinkStrap>
                </NavItem>
                <NavItem>
                  <NavLinkStrap href="#/" onClick={(e) => e.preventDefault()}>
                    Active tasks
                  </NavLinkStrap>
                </NavItem> */}
                </Nav>
              </ListGroupItem>
            </ListGroup>
          </DropdownMenu>
        </UncontrolledDropdown>

        {
          <span className="d-inline-block pr-2">
            {/* <div className="card-footer bg-white p-3 text-center d-block"> */}

            {/* <Hidden smDown> */}
            <div>
              {/* <Popconfirm
                  placement="bottom"
                  title={t('logout_popup_ask')}
                  okText={t('logout_popup_yes')}
                  cancelText={t('logout_popup_no')}
                  onConfirm={logOut}
                  onCancel={handleCancel}
                  //style={{ marginTop: '200px' }}
                > */}
              <Button
                style={{ backgroundColor: 'inherit', width: '120px' }}
                // className="ml-1"
                //onClick={logOut}
                onClick={toggle4}>
                <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                <span>{t('logout_button')}</span>
              </Button>
              {/* </Popconfirm> */}

              <BackTop />
            </div>
            {/* </Hidden> */}
          </span>
        }
        {/* <span className="d-inline-block pr-2">
          <img src={avanlogo} style={{ width: '100px' }} />
        </span> */}
      </div>

      <Modal zIndex={2000} centered isOpen={modal4} toggle={toggle4}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
              <LogOut
                className="d-flex align-self-center display-3"
                style={{ width: '75px' }}
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">{t('logout_popup_ask')}</h4>
          <p className="mb-0 text-black-50">{t('logout_popup_description')}</p>
          <div className="pt-4">
            <Button
              onClick={toggle4}
              color="neutral-secondary"
              className="btn-pill text-danger mx-1">
              <span className="btn-wrapper--label">{t('logout_popup_no')}</span>
            </Button>
            <Button
              onClick={() => {
                toggle4();
                logOut();
              }}
              color="first"
              className="btn-pill mx-1">
              <span className="btn-wrapper--label">
                {t('logout_popup_yes')}
              </span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LanguageSelect;
