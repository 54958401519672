import axios from 'axios';
import commonVariables from '../config/commonSetting';
import CompanyPropService from './companyprop.service';
import CustomizedProp from './customizedprop.service';

const hostname = commonVariables.hostname;

// const url = "http://localhost:2077/";

class AuthService {
  constructor() {
    this.currentCompProp = CompanyPropService;
    this.currentCustomizedProp = CustomizedProp;
  }
  login(emp_mail, emp_password, keepPlain, recaptchaToken) {
    return new Promise((resolve, reject) => {
      if (!recaptchaToken) {
        reject('ERROR');
        return;
      }

      axios
        .post(hostname + '/auth/login', {
          emp_mail,
          emp_password,
          keepPlain,
          recaptchaToken
        })
        .then((response) => {
          if (response.data.accessToken) {
            let data = response.data;
            let stringifydata = JSON.stringify(data);
            // console.log(response.data);
            localStorage.setItem('lite-user', stringifydata);
            this.currentCompProp.setKeepPlain(keepPlain);
            // console.log(response.data.companyList);
            // Handle Company and Property
            // console.log(response.data.companyList);
            this.currentCompProp.setInformation(response);
            this.currentCustomizedProp.setInformation(response);
            // console.log(data);
            resolve(data.default_login);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data) reject(err.response);
            else reject(err.response.data);
          } else reject(err.message);
        });
    });
  }

  logout() {
    localStorage.removeItem('lite-user');
    localStorage.setItem('lite-currentCompany', '{}');
    localStorage.setItem('lite-currentProperty', '{}');
    localStorage.setItem('lite-canRouteCompanies', '{}');
    localStorage.setItem('lite-canRouteProperties', '{}');
    localStorage.removeItem('keepPlain');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('lite-user'));
  }
}

export default new AuthService();
