import React, { useState, useEffect, useRef, createRef } from 'react';
import webSocket from 'socket.io-client';
import ReactHowler from 'react-howler';
import Timer from 'react-compound-timer';
import { Row, Col, Tooltip } from 'antd';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import CompanyPropService from '../services/companyprop.service';
import commonVariables from '../config/commonSetting';
import Hidden from '@material-ui/core/Hidden';
import { VolumeMute } from '@styled-icons/boxicons-regular/VolumeMute';
import icon1 from '../assets/images/alarm/WL_leakage_off.png';
import icon2 from '../assets/images/alarm/WL_leakage_onn.gif';

import fallicon1 from '../assets/images/alarm/fall_oof.png';
import fallicon2 from '../assets/images/alarm/fall_onn.gif';

import PageManager from './PageManager';

const countdown = 30 * 60 * 1000;
const tiRef = createRef(); //add this to reset timer

const languageMap = commonVariables.languageMap;

function useInterval(callback, delay) {
  let savedCallback = useRef();

  // 儲存新回撥
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function WaterLeakageAlarm(props) {
  let selectedLang = localStorage.getItem('i18nextLng') || 'en';
  let { t } = useTranslation();

  let [menuAnchor, setMenuAnchor] = useState(null);
  useEffect(() => {
    document.body.dir = languageMap[selectedLang].dir;
  }, [menuAnchor, selectedLang]);

  let wsalarmURL = commonVariables.wsalarmURL;
  let currentCompProp = CompanyPropService;
  let [current_Building, setCurrent_Building] = useState({
    company: currentCompProp.getCurrentCompany()
      ? currentCompProp.getCurrentCompany().company
      : null || null,
    property: currentCompProp.getCurrentProperty()
      ? currentCompProp.getCurrentProperty().property
      : null || null
  });
  let [pause, setPause] = useState(false);
  const ws = React.useRef(null);
  let [normal, setNormal] = useState(true);
  let [normal2, setNormal2] = useState(true);
  let [trigger, setTrigger] = useState(false);
  let [waterLeakAlarmIcon, setWaterLeakAlarmIcon] = useState(icon1);
  let [fallAlarmIcon, setFallAlarmIcon] = useState(fallicon1);

  useEffect(() => {
    ws.current = webSocket(wsalarmURL);

    if (ws.current) {
      //連線成功在 console 中打印訊息
      console.log('WebSocket connected!');
      //設定監聽
      initWebSocket();
    }
  }, []);

  let initWebSocket = () => {
    //對 getMessage 設定監聽，如果 server 有透過 getMessage 傳送訊息，將會在此被捕捉
    if (PageManager.getPageAccessible('PageWaterLeakage') >= 1) {
      ws.current.on('lite_water_leakage_alarm', (payload) => {
        let normal = true;
        payload = payload.filter(
          (property) =>
            property._id.property == current_Building.property &&
            property._id.company == current_Building.company
        );
        // console.log(payload);
        let property = payload[0];
        if (property) {
          // for (let property of payload) {
          // if (property._id == current_Building.property) {
          if (property.count > 0) {
            normal = false;
          } else {
            normal = true;
          }
          // break;
          // }
        }
        setNormal(normal);
      });
    }

    if (PageManager.getPageAccessible('PageCubicle') >= 1) {
      ws.current.on('lite_fall_detection_alarm', (payload) => {
        let normal2 = true;
        payload = payload.filter(
          (property) =>
            property._id.property == current_Building.property &&
            property._id.company == current_Building.company
        );
        let property = payload[0];
        if (property) {
          // for (let property of payload) {
          // if (property._id == current_Building.property) {
          if (property.count > 0) {
            normal2 = false;
          } else {
            normal2 = true;
          }
          // break;
          // }
        }
        setNormal2(normal2);
      });
    }
  };

  let checkAlarm = () => {
    if (!normal) {
      setWaterLeakAlarmIcon(icon2);
    } else {
      setWaterLeakAlarmIcon(icon1);
    }
    if (!normal2) {
      setFallAlarmIcon(fallicon2);
    } else {
      setFallAlarmIcon(fallicon1);
    }
    if (!(normal && normal2) && !pause) {
      setTrigger(true);
    } else {
      setTrigger(false);
    }

    if ((normal && normal2) || pause) {
      setTrigger(false);
    }
  };

  useInterval(() => {
    checkAlarm();
  }, 1000);

  return (
    <>
      <div>
        <ReactHowler src={['alarm.mp3']} playing={trigger} loop={true} />
        <Row gutter={22} justify="center" align="middle">
          {PageManager.getPageAccessible('PageCubicle') >= 1 && (
            <Col span={3}>
              <Tooltip title={t('fall_detection_alarm')}>
                <a href="#/PageCubicle">
                  <img
                    style={{ width: 30, height: 30, margin: 'auto' }}
                    src={fallAlarmIcon}
                    alt="fireSpot"
                  />
                </a>
              </Tooltip>
            </Col>
          )}
          {PageManager.getPageAccessible('PageWaterLeakage') >= 1 && (
            <Col span={3}>
              <Tooltip title={t('water_leakage_alarm')}>
                <a href="#/PageWaterLeakage">
                  <img
                    style={{ width: 30, height: 30, margin: 'auto' }}
                    src={waterLeakAlarmIcon}
                    alt="fireSpot"
                  />
                </a>
              </Tooltip>
            </Col>
          )}
          <Col span={2} />
          <Hidden smDown>
            {(PageManager.getPageAccessible('PageCubicle') >= 1 ||
              PageManager.getPageAccessible('PageWaterLeakage') >= 1) && (
              <Col span={16}>
                <Timer
                  initialTime={countdown}
                  direction="backward"
                  ref={tiRef}
                  startImmediately={false}
                  onStart={() => {
                    setPause(true);
                  }}
                  checkpoints={[
                    {
                      time: 30 * 60 * 1000 - 50,
                      callback: () => setPause(true)
                    },
                    {
                      time: 100,
                      callback: () => setPause(false)
                    },
                    {
                      time: 0,
                      callback: function (e) {
                        tiRef.current.reset();
                      }
                    }
                  ]}>
                  {({ start }) => (
                    <Row gutter={16} justify="center" align="middle">
                      <Col span={24} style={{ margin: 'auto' }}>
                        <Tooltip title={t('water_leakage_alarm_pause')}>
                          <Button
                            color="inherit"
                            style={{ borderColor: '#f8f9ff' }}
                            onClick={start}>
                            <VolumeMute size="22" />
                            <Timer.Minutes />
                            {t('timer_min')} &nbsp;
                            <Timer.Seconds />
                            {t('timer_sec')}
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  )}
                </Timer>
              </Col>
            )}
          </Hidden>
        </Row>
      </div>
    </>
  );
}
